import { distinctUntilChanged, fromEvent, raceWith, Subject } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { SubscriptionManager } from './subscription-manager';
import {
  AdobeIOConfig,
  CloseCallback,
  CloseEvent,
  ConnectionOptions,
  MessageCallback,
  PresenceCallback,
} from './types';
import log from './utils/log';
import {PagePresenceHandler} from "./presence/page-presence-handler";
import {PagePresenceCallback} from "./types/page-presence-callback";
import {ComponentPresenceHandler} from "./presence/component-presence-handler";
import {ComponentPresenceCallback} from "./types/component-presence-callback";
import {CursorPresenceHandler} from "./presence/cursor-presence-handler";
import {nanoid} from "nanoid";
import {COMPONENT_OBJECT_CODE, CURSOR_OBJECT_CODE, PAGE_OBJECT_CODE, PRESENCE_OPERATION} from "./utils/constants";

export const RtbeConnectionState = {
  CONNECTING: 'CONNECTING', //  Socket has been created. The connection is not yet open.
  OPEN: 'OPEN', //  The connection is open and ready to communicate.
  CLOSING: 'CLOSING', //  The connection is in the process of closing.
  CLOSED: 'CLOSED', //  The connection is closed or couldn't be opened.
};

export type WsMessage<T = any> = {
  operation: 'SUBSCRIBE' | 'UNSUBSCRIBE' | 'PUBLISH';
  objects: T[];
};

export class RtbeConnection<T = any, M = any> {
  public ws: WebSocketSubject<WsMessage<T>> | null;
  public subscriptionManager: SubscriptionManager;
  public presenceCallback?: PresenceCallback<T>;
  protected connectSignal?: AbortSignal;
  protected _state$;
  private readonly adobeIOConfig?: AdobeIOConfig;
  private readonly baseUrl: string;
  private readonly tabId: string;
  private readonly wfImsOrgId: string;
  private pagePresenceHandler?: PagePresenceHandler;
  private componentPresenceHandler?: ComponentPresenceHandler;
  private cursorPresenceHandler?: CursorPresenceHandler;

  constructor(
    public team: string,
    public view: string,
    protected messageCallback: MessageCallback<M> = () => void 0,
    protected closeCallback: CloseCallback = () => void 0,
    options?: ConnectionOptions
  ) {
    this.connectSignal = options?.connectSignal;
    this.tabId = options?.tabId || nanoid();
    this.wfImsOrgId = options?.wfImsOrgId || '';
    this._state$ = new Subject().pipe(distinctUntilChanged());
    this.subscriptionManager = new SubscriptionManager();
    this.adobeIOConfig = options?.adobeIOConfig;

    if (this.adobeIOConfig) {
      this.baseUrl = this.adobeIOConfig.useDev
        ? 'real-browser-events-dev.adobe.io'
        : 'real-browser-events.adobe.io';
    } else {
      this.baseUrl = window.location.hostname;
    }

    this.ws = this.createWebsocket();
  }

  setPresenceMessageCallback(presenceCallback: PresenceCallback<T>) {
    this.presenceCallback = presenceCallback;
  }

  startPagePresence(pageId: string, presenceCallback: PagePresenceCallback) {
    if (!this.pagePresenceHandler) {
      this.pagePresenceHandler = new PagePresenceHandler(this.ws, pageId, presenceCallback);
      this.pagePresenceHandler.run();
    }
  }

  stopPagePresence() {
    if (this.pagePresenceHandler) {
      this.pagePresenceHandler.stop();
      this.pagePresenceHandler = undefined;
    }
  }

  startComponentPresence(pageId: string, presenceCallback: ComponentPresenceCallback) {
    if (!this.componentPresenceHandler) {
      this.componentPresenceHandler = new ComponentPresenceHandler(this.ws, pageId, presenceCallback);
      this.componentPresenceHandler.run();
    }
  }

  updateComponentPresence(componentId: string, editing?: boolean) {
    if (this.componentPresenceHandler) {
      this.componentPresenceHandler.updateComponentPresence(componentId, editing || false);
    }
  }

  stopComponentPresence() {
    if (this.componentPresenceHandler) {
      this.componentPresenceHandler.stop();
      this.componentPresenceHandler = undefined;
    }
  }

  /*startCursorPresence(pageId: string, presenceCallback?: CursorPresenceCallback) {
    if (!this.cursorPresenceHandler) {
      this.cursorPresenceHandler = new CursorPresenceHandler(this.ws, pageId, this.tabId, presenceCallback);
      this.cursorPresenceHandler.run();
    }
  }

  stopCursorPresence() {
    if (this.cursorPresenceHandler) {
      this.cursorPresenceHandler.stop();
      this.cursorPresenceHandler = undefined;
    }
  }*/

  createWebsocket() {
    let startTime: number = -1;

    try {
      const closeObserver = new Subject<CloseEvent>();
      closeObserver.subscribe(event => {
        this._state$.next(RtbeConnectionState.CLOSING);
        this.onClose(event);
      });

      let url = `wss://${this.baseUrl}/browser-events-websocket-api/ws?team=${this.team}&view=${this.view}`;
      if (this.wfImsOrgId) {
        url += `&imsOrgId=${this.wfImsOrgId}`;
      }
      if (this.adobeIOConfig) {
        let { imsOrg, product, apiKey, subdomain } = this.adobeIOConfig;
        url = `wss://${this.baseUrl}/browser-events-websocket-api/ws/${subdomain}?team=${this.team}&view=${this.view}`;
        url += `&imsOrgId=${imsOrg}&productName=${product}&api_key=${apiKey || 'browser-events'}`;
      }
      if (this.tabId) {
        url += `&tabId=${this.tabId}`;
      }

      startTime = Date.now();

      const wsConfigObj = { url, closeObserver, ...(this.adobeIOConfig) && { protocol: this.adobeIOConfig.token } };
      const ws = webSocket<WsMessage<T>>(wsConfigObj);

      const race$ = this.connectSignal
        ? ws.pipe(
            raceWith(fromEvent(this.connectSignal, 'abort', { once: true }))
          )
        : ws;
      this._state$.next(RtbeConnectionState.CONNECTING);
      race$.subscribe({
        // Called whenever there is a message from the server.
        next: msg => {
          if (this.connectSignal?.aborted) {
            const err = 'Connection aborted';
            log.error(err, { team: this.team, view: this.view, tabId: this.tabId });
            this.closeCallback?.(err);
          } else {
            this.connectSignal = undefined;
            this._state$.next(RtbeConnectionState.OPEN);
            this.onMessage(msg);
          }
        },
        error: err => this.onError(err, startTime), // Called if at any point WebSocket API signals some kind of error.
        complete: () => this.onComplete(), // Called when connection is closed (for whatever reason).
      });
      return ws;
    } catch (error) {
      const totalTime = Date.now() - startTime;
      log.error(error, { team: this.team, view: this.view, tabId: this.tabId, elapsedTime: totalTime, wsCreationFailed: true });
      return null;
    }
  }

  onMessage(message) {
    if (message['message']) {
      if (message['message'].includes('Subscription created')) {
        const objectCodes = message['objectCodes'] || [];
        if (objectCodes.includes(PAGE_OBJECT_CODE)) {
            this.pagePresenceHandler?.handleSubscriptionCreation();
        }
        if (objectCodes.includes(COMPONENT_OBJECT_CODE)) {
            this.componentPresenceHandler?.handleSubscriptionCreation();
        }
      }
      return;
    }
    // if the message is a pod shutdown alert from websocket server, then re-subscribe on a new connection.
    if (
      message['terminating'] &&
      message['terminating'] ===
        'Server pod shutting down, please resubscribe with ws server'
    ) {
      this.resubscribe();
      return;
    }
    if (message.operation === PRESENCE_OPERATION && message.objectCode === PAGE_OBJECT_CODE) {
      this.pagePresenceHandler?.handlePresenceMessage(message);
    } else if (message.operation === PRESENCE_OPERATION && message.objectCode === COMPONENT_OBJECT_CODE) {
      this.componentPresenceHandler?.handlePresenceMessage(message);
    } else if (message.operation === PRESENCE_OPERATION && message.objectCode === CURSOR_OBJECT_CODE) {
      this.cursorPresenceHandler?.handlePresenceMessage(message);
    } else {
      this.messageCallback(message);
    }
  }

  onError(err, startTime: number) {
    const totalTime = Date.now() - startTime;
    log.info(`onError callback received an error`, { team: this.team, view: this.view, tabId: this.tabId, elapsedTime: totalTime });
    if (err instanceof CloseEvent) {
      log.error(
        `Connection closed unexpectedly. Reason: ${err.reason} -- Code: ${err.code}`,
        { team: this.team, view: this.view, tabId: this.tabId, elapsedTime: totalTime }
      );
    } else {
      log.error(err, { team: this.team, view: this.view, tabId: this.tabId });
    }
    this.close();
    if (this.closeCallback) {
      this.closeCallback(err);
    }
    this.stopPagePresence();
    this.stopComponentPresence();
    // this.stopCursorPresence();
  }

  onComplete() {
    this._state$.next(RtbeConnectionState.CLOSED);
    log.info('ws connection closed.', { team: this.team, view: this.view, tabId: this.tabId });
  }

  onClose(event: CloseEvent) {
    // If the ws is closed due to an expired token, then resubscribe on a new connection
    if (event.code === 4019 && event.reason === 'Auth token expired.') {
      this.resubscribe();
      return;
    }
    this.stopPagePresence();
    this.stopComponentPresence();
    // this.stopCursorPresence();
    this._state$.next(RtbeConnectionState.CLOSED);
    // For all other reasons, call the callback function provided by the client
    if (this.closeCallback) {
      this.closeCallback(event.reason);
    }
  }

  subscribe(objects: T[]) {
    if (objects && objects.length && this.ws) {
      const message: WsMessage = {
        operation: 'SUBSCRIBE',
        objects: objects,
      };
      this.ws.next(message);
      this.subscriptionManager.addSubscriptions(objects);
    }
  }

  unsubscribe(objects: T[]) {
    if (objects && objects.length && this.ws) {
      const message: WsMessage = {
        operation: 'UNSUBSCRIBE',
        objects: objects,
      };
      this.ws.next(message);
      this.subscriptionManager.removeSubscriptions(objects);
    }
  }

  publish(objects: T[]) {
    if (objects && objects.length && this.ws) {
      const message: WsMessage = {
        operation: 'PUBLISH',
        objects: objects,
      };
      this.ws.next(message);
    }
  }

  resubscribe() {
    const newWs = this.createWebsocket();
    const activeSubscriptions =
      this.subscriptionManager.activeSubscriptions.values();
    const objects = Array.from(activeSubscriptions);
    if (objects && objects.length && newWs) {
      const message: WsMessage = {
        operation: 'SUBSCRIBE',
        objects: objects,
      };
      newWs.next(message);
    }
    this.pagePresenceHandler?.resubscribe(newWs);
    this.componentPresenceHandler?.resubscribe(newWs);
    this.cursorPresenceHandler?.resubscribe(newWs);
    this.close();
    this.ws = newWs;
  }

  close() {
    if (this.ws) {
      this.ws.complete();
    }
  }

  getConnectionState$() {
    return this._state$;
  }
}
